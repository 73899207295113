import React from 'react'
import styled, { withTheme } from 'styled-components'
import translate from '../../../../modules/translate'
import Title from '../../../../components/Title'
import Link from '../../../../components/Link'
import Select from '../../../../components/Select'

import ContainerArticle from '../../../../components/ContainerArticle'

const Wrapper = styled(ContainerArticle)`
  padding-top: ${(props) => props.theme.sizes.gapCol};
  padding-bottom: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapCol};
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.mobile.sizes.gapCol};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapCol};
  }
`

const MoreObituaries = styled.div``

const Text = styled.span`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.textSecondary};
`

const StyledLink = styled(Link)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.textSecondary};
  text-decoration: underline;
`

const StyledSelect = styled(Select)`
  width: ${(props) => props.theme.sizes.col6};
  margin-right: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
    margin-right: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
    margin-right: ${(props) => props.theme.mobile.sizes.gapCol};
  }
`

const Description = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.base};
  width: ${(props) => props.theme.sizes.col6};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
`

const ObituariesCommunes = styled.div`
  display: flex;
  align-items: center;
  margin: 45px 0;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
    ${StyledSelect} {
      width: 100%;
      margin: 10px;
    }
    ${Description} {
      width: 100%;
    }
  }
`

let obituariesCommunes = React.createRef()

const HeaderObituary = ({ callBackFunction, values, ...props }) => {
  let alphabeticSortedValues = values.sort((previousValue, nextValue) => {
    if (previousValue.value < nextValue.value) {
      return -1
    }
    if (previousValue.value > nextValue.value) {
      return 1
    }
    return 0
  })
  return (
    <Wrapper {...props}>
      <Title>
        {props.theme.mode.includes('mobile') ? translate('obituaries') : translate('obituaries_southtyrol')}
      </Title>
      <MoreObituaries>
        <Text>{translate('more_obituaries')}</Text>
        <StyledLink to="https://trauer.bz">trauer.bz</StyledLink>
      </MoreObituaries>
      <ObituariesCommunes ref={obituariesCommunes}>
        <StyledSelect
          menuShouldScrollIntoView
          placeholder={translate('select_commune_selection')}
          onChange={callBackFunction}
          values={alphabeticSortedValues}
          noOptionsMessage={translate('select_no_announcement')}
        />
        <Description>{translate('obituaries_last_7_days')}</Description>
      </ObituariesCommunes>
    </Wrapper>
  )
}

HeaderObituary.defaultProps = {
  ...Select,
}

export default withTheme(HeaderObituary)
