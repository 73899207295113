import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ContainerAd from '../../../../components/ContainerAd'
import WidgetObituary from '../../widget_obituary'
import HeaderObituary from '../HeaderObituary'
import translate from '../../../../modules/translate'

const StyledContainerAd = styled(ContainerAd)`
  padding: 0;
  justify-content: space-evenly;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  margin-top: calc(${(props) => props.theme.sizes.gapVertical}*-2.5);
  > div > div {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const CustomWidgetObituary = ({ obituariesOfOneDay, index }) => {
  const generatedId = 'S' + (index + 9)
  console.log('Obit: ', obituariesOfOneDay.associatedObituaries)
  return (
    <div>
      <WidgetObituary obituariesOfOneDay={obituariesOfOneDay} index={index} />
      {index % 2 !== 0 && <StyledContainerAd adIds={[generatedId]} />}
    </div>
  )
}

const ListObituary = ({ data, ...props }) => {
  const [selectedCommunity, setCommunity] = useState(null)
  const selectCallBack = (selectedValue) => {
    setCommunity(selectedValue.value)
  }
  let distinctCommunitiesWithDeadPeople = []
  let distinctSelectableCommunitiesWithDeadPeople = [{ value: null, label: translate('all') }]
  return (
    <React.Fragment>
      <HeaderObituary values={distinctSelectableCommunitiesWithDeadPeople} callBackFunction={selectCallBack} />
      {data.obituaries.map((obituariesOfOneDay, index) => {
        obituariesOfOneDay.associatedObituaries.map((obituaryItem) => {
          if (!distinctCommunitiesWithDeadPeople.includes(obituaryItem.communityOfDeath)) {
            distinctCommunitiesWithDeadPeople.push(obituaryItem.communityOfDeath)
            distinctSelectableCommunitiesWithDeadPeople.push({
              value: obituaryItem.communityOfDeath,
              label: obituaryItem.communityOfDeath,
            })
          }
          return null
        })
        let filteredObituaries = obituariesOfOneDay.associatedObituaries.filter((obituaryItem) => {
          return obituaryItem.communityOfDeath === selectedCommunity
        })
        if (selectedCommunity === null) {
          return <CustomWidgetObituary obituariesOfOneDay={obituariesOfOneDay} index={index} />
        } else if (filteredObituaries.length > 0) {
          let obituariesOfOneDayFiltered = {
            deathDay: obituariesOfOneDay.deathDay,
            associatedObituaries: filteredObituaries,
          }
          return <CustomWidgetObituary obituariesOfOneDay={obituariesOfOneDayFiltered} index={index} />
        }
        return null
      })}
    </React.Fragment>
  )
}

ListObituary.propTypes = {
  data: PropTypes.shape({
    obituaries: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        deathDay: PropTypes.string,
        associatedObituaries: PropTypes.array,
      }),
    ),
    breadcrumb: PropTypes.shape({
      headline: PropTypes.string,
      route: PropTypes.string,
    }),
  }),
}

export default ListObituary
